import dataURItoBlob from '../../util/data-uri-to-blob';
import downloadDataURI from '../../util/download-data-uri';

export const MINIMUM_HEIGHT_FOR_VIEWER = 300;

export function heightForGraph(msg) {
  const heightOfDrawingOrPage = msg.pageVisible
    ? msg.page.height
    : msg.bounds.height;
  return Math.max(MINIMUM_HEIGHT_FOR_VIEWER, heightOfDrawingOrPage);
}

export function downloadImage(filename, dataURI) {
  if (window.navigator?.msSaveBlob) {
    window.navigator.msSaveBlob(dataURItoBlob(dataURI), filename);
  } else {
    downloadDataURI(dataURI, filename);
  }
}

export const DRAWIO_CONFIGURATION = {
  css: '.geDiagramBackdrop { background-color: #ffffff !important; } .geBackgroundPage { box-shadow: none !important; background-image: none !important; } .geDiagramContainer svg { background-image: none !important; } .mxPopupMenu { display: none !important; }',
};
