import { useCallback, useReducer, useRef, useState } from 'preact/hooks';
import useParentSystemConfiguration from '../../hooks/useParentSystemConfiguration';
import useDrawing from '../../hooks/useDrawing';
import { EMBEDDED_DRAWIO_VIEWER_URL } from '../../url-constants';
import Toolbar from './toolbar/Toolbar';
import './viewer.css';
import useDrawIoPlugins from '../../hooks/useDrawIoPlugins';
import LoadingScreen from '../common/LoadingScreen';
import { heightForGraph, DRAWIO_CONFIGURATION } from './viewer-utils';
import useParentSystemEvents from '../../hooks/useParentSystemEvents';
import useDrawIoEvents from '../../hooks/useDrawIoEvents';
import useDrawIoPluginEvents from '../../hooks/useDrawIoPluginEvents';
import useDrawIoApi from '../../hooks/useDrawIoApi';
import useDrawIoPluginsApi from '../../hooks/useDrawIoPluginsApi';
import useParentSystemApi from '../../hooks/useParentSystemApi';
import exportRequestQueueReducer, {
  EXPORT_QUEUE_ACTION,
  EXPORT_REQUEST,
} from './exportReducer';

function Wrapper({ uuid, lang }) {
  const parentSystemConfiguration = useParentSystemConfiguration();
  const xml = useDrawing(uuid, parentSystemConfiguration);

  return parentSystemConfiguration && xml ? (
    <Viewer
      parentSystemConfiguration={parentSystemConfiguration}
      xml={xml}
      uuid={uuid}
      lang={lang}
    />
  ) : null;
}

function Viewer({ parentSystemConfiguration, xml, uuid, lang }) {
  const drawIoRef = useRef(null);
  const [drawIoLoaded, setDrawIoLoaded] = useState(false);
  const [, dispatch] = useReducer(exportRequestQueueReducer, []);
  useDrawIoPlugins([
    '/src/plugins/viewer.js',
    '/src/plugins/icons.js',
    '/src/plugins/highlight.js',
  ]);
  const {
    configure: configureDrawIo,
    load: loadDrawIo,
    exportPng,
  } = useDrawIoApi(drawIoRef);
  const { renderLinkIcons, removeLinkIcons, highlight } =
    useDrawIoPluginsApi(drawIoRef);
  const { triggerLoad: triggerParentSystemLoad, triggerViewerEvent } =
    useParentSystemApi();
  const handleDrawIoConfigureEvent = useCallback(
    () => configureDrawIo(DRAWIO_CONFIGURATION),
    [configureDrawIo],
  );
  const handleDrawIoInitEvent = useCallback(() => {
    loadDrawIo(xml);
  }, [loadDrawIo, xml]);
  const handleDrawIoLoadEvent = useCallback(
    (msg) => {
      setDrawIoLoaded(true);
      triggerParentSystemLoad(heightForGraph(msg));
    },
    [triggerParentSystemLoad],
  );
  const enqueueExportPng = useCallback(
    (exportType) => {
      dispatch({ type: EXPORT_QUEUE_ACTION.ADD_REQUEST, payload: exportType });
      exportPng();
    },
    [exportPng],
  );
  const handleGetImage = useCallback(
    () => enqueueExportPng(EXPORT_REQUEST.PARENT_SYSTEM_IMAGE_REQUEST),
    [enqueueExportPng],
  );
  const handleDownloadPng = useCallback(
    () => enqueueExportPng(EXPORT_REQUEST.USER_DOWNLOAD_PNG_REQUEST),
    [enqueueExportPng],
  );

  const handleDrawIoExportEvent = useCallback(
    (msg) => {
      dispatch({
        type: EXPORT_QUEUE_ACTION.PROCESS_REQUEST,
        payload: { uuid, data: msg.data },
      });
    },
    [uuid],
  );

  const readyToListenParentSystemEvents = useParentSystemEvents({
    onGetImage: handleGetImage,
    onRenderLinkIcons: renderLinkIcons,
    onRemoveLinkIcons: removeLinkIcons,
    onHighlight: highlight,
  });
  const readyToListenDrawIoEvents = useDrawIoEvents({
    onConfigure: handleDrawIoConfigureEvent,
    onInit: handleDrawIoInitEvent,
    onLoad: handleDrawIoLoadEvent,
    onExport: handleDrawIoExportEvent,
  });
  const readyToListenDrawIoPluginEvents = useDrawIoPluginEvents({
    onViewerEvent: triggerViewerEvent,
  });

  return (
    <div className="disable-select">
      <div className="drawio">
        {readyToListenParentSystemEvents &&
        readyToListenDrawIoEvents &&
        readyToListenDrawIoPluginEvents ? (
          <iframe
            ref={drawIoRef}
            src={`${EMBEDDED_DRAWIO_VIEWER_URL}&lang=${lang}`}
            title="drawio"
            data-testid="drawio"
          />
        ) : null}
      </div>
      {drawIoLoaded ? (
        <Toolbar
          language={lang}
          drawIoRef={drawIoRef}
          uuid={uuid}
          xml={xml}
          onExportPng={handleDownloadPng}
        />
      ) : (
        <LoadingScreen
          language={lang}
          spinnerColor={parentSystemConfiguration.colors?.spinner}
        />
      )}
    </div>
  );
}

export default Wrapper;
