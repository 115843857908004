import { useCallback, useEffect, useState } from 'preact/hooks';
import * as parentSystemMessageApi from '../util/parent-system-message-api';

function useParentSystemEvents({
  onGetImage,
  onRenderLinkIcons,
  onRemoveLinkIcons,
  onHighlight,
  onSavingSuccess,
  onSavingFailed,
}) {
  const [readyToListen, setReadyToListen] = useState(false);

  const receiveParentSystemMessages = useCallback(
    (evt) => {
      if (evt.data.message) {
        switch (evt.data.message) {
          case parentSystemMessageApi.GET_IMAGE:
            onGetImage?.();
            break;
          case parentSystemMessageApi.RENDER_LINK_ICONS:
            onRenderLinkIcons?.(evt.data.links);
            break;
          case parentSystemMessageApi.REMOVE_LINK_ICONS:
            onRemoveLinkIcons?.(evt.data.objectIds);
            break;
          case parentSystemMessageApi.HIGHLIGHT:
            onHighlight?.(evt.data.opacity, evt.data.objects);
            break;
          case parentSystemMessageApi.SAVING_SUCCESS:
            onSavingSuccess?.();
            break;
          case parentSystemMessageApi.SAVING_FAILED:
            onSavingFailed?.();
            break;
          default:
            break;
        }
      }
    },
    [
      onGetImage,
      onHighlight,
      onRemoveLinkIcons,
      onRenderLinkIcons,
      onSavingFailed,
      onSavingSuccess,
    ],
  );

  useEffect(() => {
    window.addEventListener('message', receiveParentSystemMessages);
    setReadyToListen(true);
    return () => {
      window.removeEventListener('message', receiveParentSystemMessages);
    };
  }, [receiveParentSystemMessages]);

  return readyToListen;
}

export default useParentSystemEvents;
