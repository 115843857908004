export const DEFAULT_LANGUAGE = 'en';
export default {
  ZOOM: {
    en: 'Zoom',
    fi: 'Zoomaa',
  },
  ZOOM_IN: {
    en: 'Zoom in',
    fi: 'Lähennä',
  },
  ZOOM_OUT: {
    en: 'Zoom out',
    fi: 'Loitonna',
  },
  LAYERS: {
    en: 'Layers',
    fi: 'Tasot',
  },
  EXPORT: {
    en: 'Export',
    fi: 'Vie',
  },
  LOADING: {
    en: 'Loading',
    fi: 'Ladataan',
  },
};
